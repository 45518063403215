var columns = [{
  title: "ID",
  dataIndex: "id",
  key: "id",
  width: "10%",
  scopedSlots: {
    customRender: "id"
  }
}, {
  title: "团LOGO",
  dataIndex: "groupimage",
  key: "groupimage",
  width: "10%",
  scopedSlots: {
    customRender: "groupimage"
  }
}, {
  title: "团名称",
  dataIndex: "groupname",
  key: "groupname",
  width: "10%",
  scopedSlots: {
    customRender: "groupname"
  }
}, {
  title: "团公告",
  dataIndex: "groupnotice",
  key: "groupnotice",
  width: "15%",
  scopedSlots: {
    customRender: "groupnotice"
  }
}, {
  title: "团成员数量",
  dataIndex: "membernum",
  key: "membernum",
  width: "10%",
  scopedSlots: {
    customRender: "membernum"
  }
}, {
  title: "团累计交易额",
  dataIndex: "totalcolume",
  key: "totalcolume",
  width: "10%",
  scopedSlots: {
    customRender: "totalcolume"
  }
}, {
  title: "团创建时间",
  dataIndex: "groupcreatetime",
  key: "groupcreatetime",
  width: "15%",
  scopedSlots: {
    customRender: "groupcreatetime"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  //   fixed: "right",
  width: "20%",
  scopedSlots: {
    customRender: "action"
  }
}];
export { columns };