var searchKeys = [{
  key: "groupname",
  label: "交易团名称",
  placeholder: "交易团名称",
  required: false,
  rules: [],
  input: true
}, {
  key: "applyusernickname",
  label: "团长昵称",
  placeholder: "团长昵称",
  required: false,
  rules: [],
  input: true
}, {
  key: "applyuid",
  label: "团长用户ID",
  placeholder: "团长用户ID",
  required: false,
  rules: [],
  input: true
}, {
  key: "groupleaderlevel",
  label: "团长等级",
  placeholder: "团长等级",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };